$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex.css";

@font-face {
  font-family: soleil;
  font-style: normal;
  font-weight: 400;
  src: local('Soleil'), url('assets/fonts/Soleil/SoleilRegular.woff') format('woff')
}

@font-face {
  font-family: soleil;
  font-style: normal;
  font-weight: 400;
  src: local('Soleil'), url('assets/fonts/Soleil/SoleilBook.woff') format('woff')
}

@font-face {
  font-family: soleil;
  font-style: normal;
  font-weight: 300;
  src: local('Soleil'), url('assets/fonts/Soleil/SoleilLight.woff') format('woff')
}

@font-face {
  font-family: soleil;
  font-style: normal;
  font-weight: 700;
  src: local('Soleil'), url('assets/fonts/Soleil/SoleilBold.woff') format('woff')
}


/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/Roboto/roboto-v30-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-100.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('assets/fonts/Roboto/roboto-v30-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-100italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/Roboto/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/Roboto/roboto-v30-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/Roboto/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Roboto/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/Roboto/roboto-v30-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Roboto/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/Roboto/roboto-v30-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/Roboto/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-900.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/Roboto/roboto-v30-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Roboto/roboto-v30-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-v30-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-v30-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-v30-latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-300italic.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-300italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Roboto/roboto-condensed-v25-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Roboto/roboto-condensed-v25-latin-700italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
$cpe-primary-color: rgb(0,75,115);
$cpe-secondary-color: rgb(0,97,184);
$cpe-tertiary-color: rgb(93,202,245);
$cpe-other-color: rgb(246,188,65);

$cpe-dark-blue: rgb(0,75,115);
$cpe-deep-vivid-blue: rgb(0,137,204);
$cpe-light-blue: rgb(0,97,184);
$cpe-bright-blue: #0088CB; /*rgb(93,202,245);*/
$cpe-yellow: rgb(246,188,65);

$cpe-background-grey: #e6e7e8;
$cpe-background-blue: #dfeef2;

$cpe-dark-grey: rgb(51,51,51);
$cpe-medium-grey: rgb(138,138,138);
$cpe-light-grey: rgb(230,231,232);
$cpe-darken-grey: rgb(35,31,32);

a,
a:link,
a:visited,
a:hover {
  color: inherit;
  text-decoration: none;
}
/* Common layout rules */
.cpe-container {
  display: flex;
  justify-content: center;
  /* vh refers to viewport height. Very useful! */
}
.cpe-container .cpe-wrapper {
  padding: 0 40px;
}
/* For some reason it seems like the p-table component gets weird if we use flexbox, not sure why. */
.cpe-container .cpe-wrapper:not(#relatedCourses.cpe-wrapper):not(#CpePasses.cpe-wrapper):not(#mainMenu.cpe-wrapper) {
  display: flex;
  justify-content: center;
}
@media (min-width: 961px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link:first-child {
    padding: 0.75rem 1rem 0rem 1rem !important;
  }

  .annual-passes-title {
    font-family: "Soleil", sans-serif !important;
    font-size: 3.75em;
    margin-block-start: 0.125em;
    margin-block-end: 0.125em;
    color: rgb(0, 75, 115);
    font-weight: 700;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

}

//
// Occurs when the related courses goes into single column layout
//
@media (max-width: 960px) {

  .related-courses-title {
    font-size: 17px;
    font-weight: 600;
    color: #333;
  }

  .related-courses-responsive-content > p{
    font-weight: 400;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .p-datatable .p-datatable-tbody > tr.related-courses-responsive-row > td {
    border-width: 0 0 0px 0 !important;
  }

  .annual-passes-title {
    font-family: "Soleil", sans-serif !important;
    font-size: 2.5em;
    margin-block-start: 0.125em;
    margin-block-end: 0.125em;
    color: rgb(0, 75, 115);
    font-weight: 700;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h3.annual-passes-subtitle {
    text-transform: uppercase;
    font-size: 1.05em !important;
    margin-block-start: 0.125em;
    margin-block-end: 0.125em;
    color: rgb(0, 97, 184);
    letter-spacing: 2px;
    font-weight: 600;
    font-family: "Roboto Condensed", sans-serif !important;
  }

  .p-menubar .p-menubar-start {
    order: 1;
  }

  .p-menubar .p-menubar-button {
    order: 3;
    .p-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .p-menubar .p-element {
    order: 4;
  }

  #mainMenu .p-menubar-end {
    padding: 0 1rem !important;
    align-self: center !important;
    order: 2;
    display: flex !important;
    @media (max-width: 480px) {
      padding: 0 0.5rem !important;
      .p-input-icon-right > .p-inputtext {
        padding-right: 0;
      }
    }
  }

  .p-menubar-button {
    .pi {
      font-size: 1.5em;
    }
  }
}

@media (max-width: 568px) {

  #mainMenu {
    width: 90%;
    margin: 0 auto;
  }

  #mainMenu .p-menubar-start-inner {
    width: 70px;
    margin-left: 20px;
    height: auto;
  }
  #mainMenu .p-input-icon-right {
    gap: 0px;
    justify-content: flex-end;
  }

  #cpe-header-inset input[type=text i] {
    font-size: 1.5em;
    padding: 0;
    width: 100%;
  }

  #cpeBanner .cpe-wrapper.front-page #hero-text #hero-heading {
    font-size: 2em !important;
  }

  #cpeBanner .cpe-wrapper.front-page {
    height: auto !important;
  }

  .cpe-container {
    flex-direction: column;
  }

  #cpeBanner .cpe-wrapper.front-page #hero-text {
    width: 100% !important;
    font-size: 10px;
  }

  #cpeBanner #hero-text #hero-heading {
    font-size: 30px !important;
    padding: 20px 0 20px 0 !important;
  }

  #CourseTypes .p-inputgroup:not(.single-option){
    .p-selectbutton .p-button {
      padding: 10px 6px;
      font-size: 12px;
      margin: 0 3px;
      line-height: normal !important;
    }
  }
}
@media (min-width: 992px) {
  .cpe-container .cpe-wrapper,
  #CourseTypes .cpe-wrapper {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .cpe-container .cpe-wrapper,
  #CourseTypes .cpe-wrapper {
    width: 1170px;
  }
}
/* End common layout rules */

/* Font overrides */
/* Stuff that should be Soleil */
#CpePasses h1,
#CpePasses h3,
#cpe-explore-in-demand h2,
#CourseTypes,
.label,
.button-label,
.p-dropdown-label,
button,
button:hover,
button.p-button,
button.p-button:hover {
  font-family: 'Soleil', sans-serif!important;
}
/* Stuff that should be Roboto */
body,
.related-courses .p-element .p-sortable-column,
#CpePasses p {
  font-family: 'Roboto', sans-serif!important;
}
/* Stuff that should be Roboto Condensed */
h1, h2, h3, #CpePasses h3, #CpePasses .p-card-content .p-button-label,
.review-text, #CpePasses .p-card-title {
  font-family: 'Roboto Condensed', sans-serif!important;
}
/* End Font overrides */

body {
  background-color: #fff;
  color: #808080;
  margin: 0;
  font-size: 16px;
}
.grey-background {
  background-color: $cpe-background-grey;
}
h1 {
  font-size: 1.5em;
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.column-heading h1 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.cpe-bright-blue-text {
  color: $cpe-bright-blue;
}
.cpe-bright-blue-background {
  color: #fff;
  background-color: $cpe-bright-blue;
}
.cpe-deep-vivid-blue-background {
  color: #fff;
  background-color: $cpe-deep-vivid-blue;
}
.cpe-dark-blue-text {
  color: $cpe-dark-blue;
}
.cpe-yellow-text {
  color: $cpe-yellow;
}
.cpe-dark-blue-background {
  color: #fff;
  background-color: $cpe-dark-blue;
}
.cpe-dark-blue-background .grid {
  color: #ffffff;
}
a.more-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bolder;
  color: $cpe-dark-blue;
}
.cpe-dark-blue-background button,
.cpe-dark-blue-background button:hover,
.cpe-dark-blue-background button.p-button,
.cpe-dark-blue-background button.p-button:hover,
.cpe-dark-blue-text button,
.cpe-dark-blue-text button:hover,
.cpe-dark-blue-text button.p-button,
.cpe-dark-blue-text button.p-button:hover,
.view-all-passes-btn {
  border: 1px solid $cpe-yellow;
  background-color: $cpe-yellow !important;
  color: $cpe-dark-blue;
}
.elevated {
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 25%);
}
/* Styles for the header (banner and main-nav) */
#header-banner {
  position: relative;
  //z-index: 400;
  background-color: $cpe-light-blue;
}
#cpeBanner {
  //position: relative;
  height: auto;
  background: linear-gradient(to right, #003366, #336699, #003366);
  //background: radial-gradient(blue 5%, white 15%, blue 60%);
}
#cpeBanner .cpe-wrapper.front-page {
  //position: relative;
  height: 360px;
}
#cpeBanner .cpe-wrapper.front-page #hero-text {
  width: 50%;
  text-align: right;
  /*padding: 120px 0px;*/
}
#cpeBanner #hero-text #hero-heading {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 30px 0 30px 0;
  font-size: 2.25em;
  font-weight: 300;
}
#cpeBanner .cpe-wrapper.front-page #hero-text #hero-heading {
  font-size: 5em;
  line-height: 1em;
}
#cpeBanner #hero-text #hero-message {
  //display: none;
  color: #fff;
  font-weight: 200;
}
#cpeBanner .cpe-wrapper.front-page #hero-text #hero-message {
  display: inherit;
  line-height: 1em;
  margin: 0;
  padding: 0;
  font-size: 2em;
}
header .p-menubar {
  padding: 0;
  background: #fff;
  border: 0px solid transparent;
}
.p-menubar-start-inner {
  margin: 0px auto;
  padding: 0;
}
#logo-title, #logo, #logo-title, #logo a, #logo a img {
  float: left;
  height: auto;
  display: block;
  padding: 0;
  background: transparent;
  width: 140px;
}
#logo-title, #logo, #logo-title, #logo a {
  margin: 0;
}
// #logo-title {
//   position: absolute;
//   left: 0;
//   top: 0;
// }

// #logo-image, #logo a img {
//   margin-top: -1px;
//   box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 25%);
// }
#mainMenu .p-menubar {
  padding: 10px 40px 0 40px;
}
ul.p-menubar-root-list {
  margin-left: 15px !important;
  flex-wrap: nowrap;
}
span.p-input-icon-right.ng-star-inserted {
  display: flex;
  align-items: center;
  gap:34px;
}
#mainMenu .p-menubar-end i#search-input-icon {
  color: $cpe-darken-grey;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin: 5px 0 0 0;
}
p.signmenu {
  color: $cpe-dark-grey;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}
.input-bar-box {
  display: flex;
  align-items: center;
  // flex-direction: row-reverse;
  gap: 10px;
}
.cartmenu {
  position: relative;
  cursor: pointer;
}
.cartmenu span.badge {
  position: absolute;
  top: -8px;
  right: -12px;
  width: 20px;
  height: 20px;
  background: $cpe-light-blue;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  line-height: 1.4;
  color: #fff;
  font-size: 14px;
}
.header-list-menu .p-input-icon-left>i, .header-list-menu .p-input-icon-right>i {
  position: static !important;
}
p-menubarsub.p-element {

  .p-menuitem-text {
    color: $cpe-dark-grey !important;
    font-weight: bold;
  }
  .p-menuitem-icon {
    color: $cpe-darken-grey !important;
  }
}

#mainMenu .p-menubar-end {
  margin-left: 5px;
  padding: 0.75rem 1rem 0 1rem;
  align-self: flex-end;
  margin-left:auto;
}
.shopping-cart .p-menuitem-text {
  display: none;
}
.shopping-cart .p-menuitem-icon {
  font-size: 1.25rem;
}
/* END header styles */
/* Footer Styles */
footer {
  margin-top: 2em;
}
#cpe-footer h1 {
  letter-spacing: 2px;
  font-weight: 500;
}
/* END Footer Styles */
/* Home Page Styles */
#cpe-header-inset.cpe-wrapper {
  margin-top: -40px;
}
#cpe-header-inset .inset {
  padding: 8px 8px 0px 8px;
  color: #fff;
  /*font-size: 1.25em;*/
}
#cpe-header-inset input[type="text" i] {
  font-size: 1.5em;
  padding: 0.1em;
}
#cpe-header-inset .p-input-icon-right>i {
  font-size: 1.25em;
  top: 40%;
}
#cpe-browse-courses .cpe-inset.cpe-inset {
  margin-top: -120px;
}
#cpe-browse-courses .cpe-inset.cpe-inset .inset {
  padding: 8px 8px 0px 8px;
}
.yellow-stripe {
  height: 10px;
  background-color: $cpe-yellow;
}
.inset .grid .search {
  background-color: $cpe-dark-blue;
  padding-left: 40px;
}
.inset .grid .slider {
  background-color: $cpe-deep-vivid-blue;
  padding-right: 40px;
}
/*  */
#CpePasses.cpe-wrapper {
  /*padding: 0 20px;*/
}
#CpePasses.cpe-wrapper .surface-card {
  /*box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 25%);Added a style for shadows*/
}
#CpePasses.cpe-wrapper .grid {
  margin-left: 0;
  margin-right: 0;
}
#contactForm.cpe-wrapper {
  padding: 0 60px;
}
.full-width-banner {
  height: 300px;
}
/* Speech Bubbles for Front Page Testimonial banner */
#speech-bubble {
  width: 120px;
  height: 80px;
  background: #fff;
  position: absolute;
  left:100px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
#speech-bubble:before {
  content:"";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid #fff;
  border-bottom: 13px solid transparent;
  margin: 13px 0 0 -25px;
}
/* END Speech Bubbles */
/* END Home Page Styles */
/* JG: Disabled this style for now since the front page is being redesigned.
.cpe-wrapper button,
.cpe-wrapper button:hover,
.cpe-wrapper button.p-button,
.cpe-wrapper button.p-button:hover {
  border: 1px solid $cpe-dark-blue;
  background-color: #fff;
  color: $cpe-dark-blue;
}
 */
.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: $cpe-bright-blue!important;
  background-color: $cpe-bright-blue!important;
  color: $cpe-bright-blue!important;
}
/* Customization of scrollpanel */
.p-scrollpanel {
  p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.blue {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: $cpe-bright-blue;
      opacity: 1;
      transition: background-color .2s;

      &:hover {
        background-color: $cpe-bright-blue;
      }
    }
  }
}
/* Course Type Control */
#CourseTypes {
  display: flex;
  justify-content: center;
  @media (max-width: 991px) {
    justify-content: left;
  }
}
#CourseTypes .cpe-wrapper {
  display: flex;
  padding: 0 40px;
  position:relative;
  .field {
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    .p-inputgroup {
      width: 100%;
    }
  }
}
#mainMenu.cpe-wrapper {
  padding: 0!important;
}
#CourseTypes {
  background-color: $cpe-medium-grey;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Soleil", sans-serif !important;
}
#CourseTypes .cpe-wrapper::before {
  content: "";
  position: absolute;
  left: -195%;
  top: 0;
  background: #fff;
  height: 100%;
  width: 210%;
  z-index: 1;
}

@media (max-width: 767px) {

  .cpe-container .cpe-wrapper {
    padding: 0 20px;
  }

  #CourseTypes .cpe-wrapper {
    padding: 0 0px 0 20px !important;
  }

  #CourseTypes .cpe-wrapper::before {
    background: #fff;
    height: 100%;
    width: 50px;
    left: 0;
  }

  #CourseTypes .cpe-wrapper .field .p-inputgroup:first-child {
    width: 100%;
  }

  #CourseTypes .p-inputgroup-addon:first-child {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  #CourseTypes {
    background-color: transparent;
  }

  #CourseTypes .p-selectbutton .p-component:first-child {
    margin-left: 0 !important;
  }

  #CourseTypes .p-selectbutton .p-button:not(.p-button.p-highlight) {
    background-color: gray;
    color: #fff;
    border: none;
    display: inline-block;
  }
  #CourseTypes .p-selectbutton .p-button {
    margin: 0 5px;
  }

  #CourseTypes .p-inputgroup-addon:first-child {
    border: none;
  }

  #CourseTypes .cpe-wrapper .field .two-option p-selectbutton {
    width: 100%;
  }

  #CourseTypes .cpe-wrapper {
    width: 100%;
  }

  #CourseTypes .cpe-wrapper .field .p-inputgroup:first-child {
    width: auto;
    white-space: nowrap;
  }

  #CourseTypes .cpe-wrapper .field .single-option p-selectbutton .p-button {
    width: 100%;
  }

  #CourseTypes .cpe-wrapper .field .single-option p-selectbutton {
    width: 100%;
  }

  #CourseTypes .cpe-wrapper .field {
    display: flex;
    z-index: 1;
    width: 100%;
  }

  #CourseTypes .cpe-wrapper .single-field {
    flex-wrap: nowrap;
  }

  #CourseTypes .cpe-wrapper .field .two-option p-selectbutton .p-button {
    width: calc(100% / 2 - 10px);
    height: 37px;
  }

  #CourseTypes .cpe-wrapper .field .three-option{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  #CourseTypes .cpe-wrapper .field .three-option p-selectbutton .p-button {
    width: calc(33% - 10px);
    height: 37px;
  }

  #CourseTypes .cpe-wrapper .field .three-option p-selectbutton {
    width: 100%;
  }

}
@media (max-width:767px) and (min-width:575px) {
  #CourseTypes .cpe-wrapper .field .three-option{
    padding-left: 8px;
  }
}
#CourseTypes .typeLabel {
  color: rgb(51 51 51);
}
#CourseTypes .button-label {
  font-weight: bold;
}
#CourseTypes .p-inputgroup-addon {
  background-color: #fff;
  border-top: none;
  border-left: none;
  border-bottom: none;
}
#CourseTypes .p-selectbutton :not(.p-button.p-highlight) {
  background-color: transparent;
  color: #fff;
  border: none;
}
#CourseTypes .field {
  margin-bottom: 0;
}
#CourseTypes .p-inputgroup-addon:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/* End Course Type Control */
#cpeContent span.label {
  font-size: 1.25em;
  color: $cpe-dark-blue;
}

#cpeContent h2.label {
  font-size: 1.3em;
  font-weight: bolder;
  color: $cpe-dark-blue;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}

/* Find the start of the first converted paragraph text after a h2 label */
#cpeContent h2.label + div > p {
  margin-top: 0px;
}

.cpe-content-section {
  line-height: 1.3;
  color: black;
}

.cpe-content-section ul {
  padding-inline-start: 0px;
  list-style: none;
  list-style-position: outside;
  margin-top: 0px;
}

.cpe-content-section > ul li:before{
  content:"•";
  font-size: 12pt;
  padding-right: 10px;
}

.cpe-content-section > ul ul li:before{
  content: "\2010";
  font-size: 12pt;
  padding-right: 10px;
  margin-left: 15px;
}

//
// Primary emphasis list
//

.cpe-content-section ul li {
  list-style: none;
  counter-increment: list;
  display: table-row;
}

.cpe-content-section ul li:before {
  content: "•";
  display: table-cell;
  text-align: right;
  padding-right: 10px;
}

.cpe-content-section ul {
  columns: 1;
  column-gap: 25px;
  -webkit-columns: 1;
  -moz-columns: 1;
  list-style-position: outside;
  margin-left: 0px;
}

//
// Secondary emphasis list
//

.cpe-content-section ul ul li {
  list-style: none;
  counter-increment: list;
  display: table-row;
}

.cpe-content-section ul ul li:before {
  content: "\2010";
  display: table-cell;
  text-align: right;
  padding-right: 10px;
}

.cpe-content-section ul ul {
  columns: 1;
  column-gap: 25px;
  -webkit-columns: 1;
  -moz-columns: 1;
  list-style-position: outside;
  margin-left: 0px;
}

.cpe-content-section li {
  //This allows the second line to start indented after the bullet when the line wraps but it causes severe display issues
  //on some types of content. /courses/c79ca9f2-8f93-4472-b836-cb2a1e79e2bd
  //display: flex;
  break-inside: avoid-column;
}


/* Customization of side column buttons */

.cart-buttons {
  .p-button-label {
    font-size: 13px !important;
    font-weight: 700;
  }
  .p-button:focus {
    box-shadow: none;
  }
  #add-to-cart-btn, #add-to-cart-btn-mobile {
    background-color: #fff;
    button {
      border: none;
      background-color: #fff;
      color: $cpe-deep-vivid-blue;
    }
  }
  button {
    height: 100%;
    display: flex !important;

    padding-bottom: 2px;
    line-height: 1.5;
    padding-top: 6px;
  }

}

.cpe-yellow-btn button,
.cpe-yellow-btn button:hover,
.cpe-yellow-btn button.p-button,
.cpe-yellow-btn button.p-button:hover {
  border: 1px solid $cpe-yellow;
  background-color: $cpe-yellow;
  font-size: 0.75rem;
  font-weight: bold !important;
  text-transform: uppercase;
  color: $cpe-dark-grey;
  text-align: left;
}
.cpe-blue-btn button,
.cpe-blue-btn button:hover,
.cpe-blue-btn button.p-button,
.cpe-blue-btn button.p-button:hover,
.cpe-blue-btn {
  border: 1px solid $cpe-deep-vivid-blue;
  background-color: $cpe-deep-vivid-blue;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}
.p-carousel {
  text-align: center;
  color: #fff;
  .p-carousel-header {
    text-align: left;
  }
}
.p-carousel-prev,
.p-carousel-next {
  color: #fff!important;
}

#header-banner .p-carousel .p-icon {
  height: 2rem;
  width: 2rem;
}
#rightColumn .price-credit {
  color: rgb(51 51 51);
}
#rightColumn #course-available-date {
  text-transform: uppercase;
  .p-dropdown {
    border-color: $cpe-dark-grey;
    .p-dropdown-item {
      color: $cpe-dark-grey;
    }
    .p-dropdown-panel {
      font-size: 13px;
    }
  }
  .p-dropdown-trigger {
    color: $cpe-dark-grey;
  }
  .p-dropdown .p-dropdown-label
  {
    padding-top: 2.6px;
    padding-bottom: 0;
    line-height: 30px;
    font-size: 13px;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    font-weight: bold;
    color: $cpe-dark-grey;
    text-align: left;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: $cpe-dark-grey;
  }

}
#rightColumn .p-carousel {
  background-color: $cpe-dark-blue;
  color: #fff;
}
#rightColumn .p-carousel-header h3 {
  text-transform: uppercase;
  margin-bottom: 0px;
  text-align: center;
}
#rightColumn .p-carousel-prev,
#rightColumn .p-carousel-next {
  color: #fff!important;
}
.review-text {
  font-size: 0.75em;
}
/* End right-column styles */
/* Table Customization */
.p-datatable .p-datatable-header {
  padding: 0em 1em!important;
  background-color: $cpe-dark-blue !important;
  color: #fff!important;
}
.p-datatable .p-datatable-header h1 {
  font-weight: normal;
  //margin: 0!important;
}
.related-courses .p-datatable-thead {
  tr {
    th {
      color: $cpe-dark-grey !important;
      .p-sortable-column-icon {
        color: $cpe-dark-grey !important;
      }
    }
  }
}

.emphasis-row {
  font-weight: bolder;
  color: $cpe-dark-grey !important;
  font-family: "Roboto", sans-serif !important;
}
.non-emphasis-row {
  color: $cpe-dark-grey !important;
  font-family: "Roboto", sans-serif !important;
}

.emphasis-row button {
  font-weight: bolder;
}
.related-courses .p-datatable-wrapper button,
.related-courses .p-datatable-wrapper button:hover,
.related-courses .p-datatable-wrapper button.p-button,
.related-courses .p-datatable-wrapper button.p-button:hover,
.related-courses .p-datatable-wrapper .view-pass-details-btn {
  font-family: Soleil,sans-serif!important;
  border: 1px solid $cpe-dark-blue;
  background-color: #fff;
  color: $cpe-dark-blue;
  text-transform: uppercase;
  padding: 0.5rem !important;
}
.related-courses .emphasis-row button,
.related-courses .emphasis-row button:hover,
.related-courses .emphasis-row button.p-button,
.related-courses .emphasis-row button.p-button:hover,
.related-courses .emphasis-row a{
  font-family: Soleil,sans-serif!important;
  border: 1px solid $cpe-deep-vivid-blue;
  background-color: $cpe-deep-vivid-blue;
  color: #fff;
  text-transform: uppercase;
  padding: 0.5rem !important;
}
#CpePasses {
  text-align: center;
}
.p-card .p-card-body {
  padding: 1rem 0 1rem 0 !important;
}
.p-card .p-card-title {
  margin: 0 !important;
}
#CpePasses h1 {
  font-size: 5vw;
  margin-block-start: .125em;
  margin-block-end: .125em;
  color: $cpe-dark-blue;
  font-weight: 700;
}
#CpePasses h3 {
  text-transform: uppercase;
  font-size: 2vw;
  margin-block-start: .125em;
  margin-block-end: .125em;
  color: $cpe-light-blue;
  letter-spacing: 2px;
  font-weight: 600;
}
#CpePasses .p-card .p-button {
  padding: 2px;
}
#CpePasses .odd .p-button {
  border: 1px solid $cpe-deep-vivid-blue;
  background-color: $cpe-deep-vivid-blue;
  font-weight: 500 !important;
  letter-spacing: 1px;
}
#CpePasses .even .p-button {
  border: 1px solid $cpe-light-blue;
  background-color: $cpe-light-blue;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

#CpePasses .p-card {
  border-radius: 10px;
}
#CpePasses .p-card-header {
  margin-top: -15px;
}
#CpePasses .p-card-title {
  text-transform: uppercase;
  height: 20px;
  color: $cpe-dark-grey;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1.2rem;
  white-space: nowrap;
}
#CpePasses .p-card-content p {
  height: 60px;
  margin: 0;
  font-weight: 400;
  color: $cpe-dark-grey;
  font-size: 13px;
}
#CpePasses .p-card-body {
  text-align: center;
}

#CpePasses .view-all-passes-btn {
  color: #fff;
  font-family: Soleil,sans-serif!important;
  border: 1px solid $cpe-light-blue;
  background-color: $cpe-light-blue !important;
  font-weight: 700;
}

#CpePasses .action-buttons {
  height: 70px;
}

@media only screen and (min-width: 1280px) {

}

/* Course Page Contact Form Customization */
#contactForm {
  h1 {
    margin-block-start: .125em;
    margin-block-end: .125em;
    color: $cpe-dark-grey;
    font-family: "Soleil", sans-serif !important;
  }

  h2.email-subscribe-title {
    color: $cpe-dark-grey;
    font-family: "Soleil", sans-serif !important;
    font-size: 2em;
    font-weight: bold;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 1px;
  }

  h2.email-subscribe-title > span.small-text {
    font-size: 0.8em;
  }

  .divider {
    width: 20%;
    margin-bottom: 30px;
    hr {
      background-color: rgb(246, 188, 65);
      border: none;
      height: 4px;
    }
  }

  h3 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;
    color: $cpe-dark-grey;
    margin-block-start: .125em;
    margin-block-end: .125em;

    .high-light-text {
      font-weight: 700;
    }
  }

  input {
    background: #e6e7e8 !important;
    font-weight: 400;
    font-family: "Roboto", sans-serif !important;
  }

  .p-dropdown {
    background: #e6e7e8 !important;

    .p-dropdown-label, .p-multiselect-label {
      font-weight: 400;
      font-family: "Roboto", sans-serif !important;
    }
  }

  .subscribe-btn {
    border: 1px solid $cpe-dark-blue;
    background-color: $cpe-dark-blue;
    font-weight: 700;
  }
}

/* Responsive Overrides */
@media (min-width: 568px) {
  #contactForm h1 {
    font-size: 3vw;
    .small-text {
      font-size: 2.5vw;
    }
  }
  #contactForm h3 {
    font-size: 3vw;
  }
  #CpePasses .p-card-header {
    margin-top: -10px;
  }
  #cpeContent div.cpe-emphasis ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}

@media (max-width: 360px) {
  .cart-buttons .p-button-label {
    font-size: 10px !important;
  }
}

@media (min-width: 992px) {

  .cpe-yellow-btn button,
  .cpe-yellow-btn button:hover,
  .cpe-yellow-btn button.p-button,
  .cpe-yellow-btn button.p-button:hover {
    font-size: 1em;
    font-weight: normal;
  }
  .cpe-blue-btn button,
  .cpe-blue-btn button:hover,
  .cpe-blue-btn button.p-button,
  .cpe-blue-btn button.p-button:hover {
    font-size: 1em;
    font-weight: normal;
  }

  //
  // Primary emphasis list
  //

  #cpeContent div.cpe-emphasis ul li {
    list-style: none;
    counter-increment: list;
    display: table-row;
  }

  #cpeContent div.cpe-emphasis ul li:before {
    content: "•";
    display: table-cell;
    text-align: right;
    padding-right: 10px;
  }

  #cpeContent div.cpe-emphasis ul {
    columns: 2;
    column-gap: 25px;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style-position: outside;
    margin-left: 0px;
  }

  //
  // Secondary emphasis list
  //

  #cpeContent div.cpe-emphasis ul ul li {
    list-style: none;
    counter-increment: list;
    display: table-row;
  }

  #cpeContent div.cpe-emphasis ul ul li:before {
    content: "\2010";
    display: table-cell;
    text-align: right;
    padding-right: 10px;
  }

  #cpeContent div.cpe-emphasis ul ul {
    columns: 1;
    column-gap: 25px;
    -webkit-columns: 1;
    -moz-columns: 1;
    list-style-position: outside;
    margin-left: 0px;
  }

  .review-text {
    font-size: 1em;
    font-weight: normal;
  }
  #CpePasses .p-card-header {
    margin-top: -15px;
  }
  #CpePasses h1 {
    font-size: 4em;
  }
  #CpePasses h3 {
    font-size: 1.5em;
  }
  #CpePasses .p-button-label {
    font-size: 1.1em!important;
  }

  p-button.cpe-pass-widget-buy-button span.p-button-label {
    font-weight: 500 !important;
    margin-left: 5px;
    margin-right: 5px;
  }

  #contactForm h1 {
    font-size: 2em;
    .small-text {
      font-size: 0.8em;
    }
  }
  #contactForm h3 {
    font-size: 1.5em;
  }

  h2.email-subscribe-title > span.small-text {
    font-size: 0.8em;
  }
}
@media (min-width: 1200px) {

  #contactForm h1 {
    font-size: 3.5em;
  }
  #contactForm h3 {
    font-size: 2em;
  }

  #contactForm {
    h3.contact-form-subheader {
      font-size: 23px !important;
    }
  }

}
/* Applicable Passes Widget */
#applicablePasses {
  font-size: 0.75em;
  font-weight: normal;
}
#applicablePasses {
  font-size: 10px;
  font-weight: bolder;
  text-align: center;
}
.pass-widget-container {
  height: 40px;
  width: 100%;
}
.pass-widget-image {
  height:40px;
  width: 40px;
}
.pass-widget-image {
  background-image: url("assets/passes/passicons.svg");
  background-repeat: no-repeat;
  background-size: 800%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pass-widget-na {
  opacity: 0.5;
}
.pass-widget-disabled {
  z-index: 10;
  width: 50px;
  border-bottom: 3px solid black;
  -webkit-transform: translateY(-22px) translateX(2px) rotate(-45deg);
  position: absolute;
}
.pass-widget-disabled {
  -webkit-transform: translateY(0px) translateX(0px) rotate(-45deg);
}
#applicablePasses, #cpe-passes {
  .weekend-icon {
    background-position: 6px 0;
  }
  .self-icon {
    background-position: -90px 0;
  }
  .anytime-icon {
    background-position: -185px 0;
  }
  .combo-icon {
    background-position: -280px 0;
  }
}
/* END Applicable Passes Widget */

/* START Most popular courses (Home Page) */

#cpe-most-popular-courses {
  .cpe-wrapper {
    @media(max-width:767px) {
      width: 100%;
      padding: 0;
    }
  }

  .grid {
    @media(max-width:767px) {
      width: 100%;
    }
  }

  .card-box {
    height: 330px;
    position: relative;

    @media(max-width:767px) {
      height: auto;
    }

    .course-box {
      @media(max-width:767px) {
        margin-left: auto;
      }

      @media(max-width:480px) {
        margin: 0 auto;
      }
    }
  }

  span {
    display: block;
  }

  .course-offering-card {
    border-radius: 3px;
    border-color: lightgray;

    .img-box {
      height: 250px;

      @media(max-width:767px) {
        height: 200px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    h1 {
      font-family: "Soleil", sans-serif !important;
    }

    .p-button {
      background-color: #fff;
      color: $cpe-dark-blue;
      text-transform: uppercase;
      position: absolute;
      bottom: 50px;

      @media(max-width:480px) {
        position: static;
      }
    }
  }

  .course-guarantee-card {
    border-radius: 3px;
    border-color: lightgray;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }

    .p-button {
      background-color: #fff;
      color: $cpe-dark-blue;
      text-transform: uppercase;
      position: absolute;
      bottom: 50px;

      @media(max-width:480px) {
        position: static;
      }
    }
  }

  .product-image {
    width: 50%;
  }

  .p-icon {
    color: #6c757d !important;
    height: 2rem;
    width: 2rem
  }

  .popular-card {
    h1 {
      font-family: "Soleil", sans-serif !important;
      font-size: 50px;
      color: $cpe-dark-grey
    }

    h3 {
      font-family: "Roboto", sans-serif !important;
      font-weight: 400;
      color: $cpe-dark-grey;
      font-size: 24px;
    }
  }

  // .p-carousel {
  //   width: 100%;
  // }

  img.product-image {
    height: 100%;
    object-fit: contain;
    width: 100%;
    padding: 25px;
  }

  .product-item {
    margin: 16px 1rem 0;
    max-width: 160px;
    max-height: 240px;
  }

  .product-img-box {
    background: #fff;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.19), 0 0 10px rgba(0, 0, 0, 0.19) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
  }

  .course-title {
    font-family: "Roboto", sans-serif !important;
    color: $cpe-dark-grey;
  }

  .course-count {
    color: $cpe-deep-vivid-blue;
  }

  .view-all-courses-btn {
    font-family: Soleil,sans-serif!important;
    border: 1px solid $cpe-dark-grey;
    background-color: #fff;
    color: $cpe-dark-grey;
    text-transform: uppercase;
    font-weight: bold;
  }
}

/* END Most popular courses (Home Page) */

#cpe-explore-in-demand {

  h1 {
    font-family: "Soleil", sans-serif !important;
    font-size: 55px;
    color: $cpe-dark-grey;
  }
  h3 {
    font-family: "Roboto", sans-serif !important;
    font-size: 32px;
    font-weight: 400;
    color: $cpe-dark-grey;
  }
  h2 {
    font-size: 30px;
  }
  p-tag {
    display: inline-block;
  }
  .p-tag {
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid $cpe-dark-grey;
    border-radius: 5px;
    background-color: transparent;
    color: $cpe-dark-grey;
    cursor: pointer;
  }
  .active-category .p-tag {
    background-color: #0c0d0e;
    color: $cpe-background-grey;
  }
  .p-tabview .p-tabview-nav {
    font-family: "Soleil", sans-serif !important;
    font-size: 27px;
    font-weight: bold !important;
  }
  .p-tabview .p-tabview-nav,
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
  .p-tabview .p-tabview-nav li .p-tabview-nav-link,
  .p-tabview .p-tabview-panels {
    background-color: $cpe-background-grey;
  }
  .p-tabview .p-tabview-nav li.p-highlight {
    border-color: transparent;
    border-width: 0 0 0 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #E0E0E0;
    border-width: 0 0 8px 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: $cpe-dark-grey;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: $cpe-dark-grey;
  }
  // Customize the carousel controls
  .p-carousel-indicators {
    display: none;
  }
  .p-icon {
    color: $cpe-dark-grey;
    height: 2em;
    width: 2rem;
  }

  .p-component.p-carousel.p-carousel-horizontal,
  .p-component.p-carousel.p-carousel-horizontal .p-carousel-content,
  .p-component.p-carousel.p-carousel-horizontal .p-carousel-content .p-carousel-container,
  .p-component.p-carousel.p-carousel-horizontal .p-carousel-content .p-carousel-container .p-panel-content {
    height: 100%;
    color: $cpe-dark-grey;
  }

  .topic-card .p-panel-header {
    border-color: $cpe-medium-grey;
    background-color: $cpe-medium-grey;
  }
  .ss-card .p-panel-header {
    border-color: #0a65aa; //#0d3c61;
    background-color: #0a65aa; //#0d3c61;
  }
  .virtual-conference-card .p-panel-header {
    border-color: $cpe-medium-grey;
    background-color: $cpe-medium-grey;
  }
  .in-person-conference-card .p-panel-header {
    border-color: #00bcd4;
    background-color: #00bcd4;
  }
  .seminar-card .p-panel-header {
    border-color: #3fa21c;
    background-color: #3fa21c;
  }
  .webinar-card .p-panel-header {
    border-color: $cpe-deep-vivid-blue;
    background-color: $cpe-deep-vivid-blue;
  }
  .p-panel-header {
    font-family: "Soleil", sans-serif !important;
    font-size: 17px;
    color: #fff;
    text-align: center;
    display: block;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
  }
  .p-panel-content {
    text-align: left;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
    .course-title {
      font-family: "Roboto", sans-serif !important;
      font-size: 16px;
    }
    .course-content {
      font-family: "Roboto", sans-serif !important;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .course-price {
    .pi {
      font-size: .25rem;
      vertical-align: middle;
    }
  }
  .course-date {
    .pi {
      font-size: .25rem;
      vertical-align: middle;
    }
  }

  .course-details-btn {
    border: none;
    background-color: #fff;
    color: $cpe-dark-grey;
    font-weight: 700;
    padding: 0;
    .pi {
      color: $cpe-deep-vivid-blue;
    }
  }

  .view-all-courses-btn {
    font-family: Soleil,sans-serif!important;
    border: 1px solid $cpe-dark-grey;
    background-color: #fff;
    color: $cpe-dark-grey;
    text-transform: uppercase;
    font-weight: bold;
  }
}
#cpe-upcoming-conferences {

  h1 {
    font-family: Soleil,sans-serif!important;
    font-size: 30px;
    font-weight: bold;
    color: $cpe-dark-grey;
  }

  .p-carousel {
    text-align: left;
  }
  // Customize the carousel controls
  .p-carousel-indicators {
    display: none;
  }
  .p-icon {
    color: $cpe-dark-grey;
    height: 2em;
    width: 2rem;
  }

  .p-component.p-carousel.p-carousel-horizontal,
  .p-component.p-carousel.p-carousel-horizontal .p-carousel-content,
  .p-component.p-carousel.p-carousel-horizontal .p-carousel-content .p-carousel-container,
  .p-component.p-carousel.p-carousel-horizontal .p-carousel-content .p-carousel-container .p-panel-content {
    height: 100%;
    color: $cpe-dark-grey;
  }
  .p-panel {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px; // This seems wrong, but matches the comp
    border-bottom-left-radius: 5px; // This seems wrong, but matches the comp
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
  }
  .p-panel .p-panel-header,.upcome-info {
    width: 100%;
  }
  .p-panel .p-panel-header,.upcome-info img {
    height: 100px;
    position: relative;
    padding: 0px;
    color: #fff;
    text-align: center;
    border: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px; // This seems wrong, but matches the comp
    border-bottom-left-radius: 5px; // This seems wrong, but matches the comp
    width: 100%;
  }
  .p-panel-header .label {
    font-family: "Roboto", sans-serif !important;
    padding: 10px;
    color: $cpe-medium-grey;
    background-color: rgb(243,243,244);
    position: absolute;
    bottom: 0px;
    left: 0px;

  }
  .p-panel-content {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    //box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
  }
  .course-price {
    .pi {
      font-size: .25rem;
      vertical-align: middle;
    }
  }
  .course-date {
    .pi {
      font-size: .25rem;
      vertical-align: middle;
    }
  }

  .course-details-btn {
    border: none;
    background-color: #fff;
    color: $cpe-dark-grey;
    font-weight: 700;
    padding: 0;
    .pi {
      color: $cpe-deep-vivid-blue;
    }
  }
}

#cpe-explore-all-topics {
  .cpe-wrapper {
    @media(max-width:767px) {
      width: 100%;
      padding: 0;
    }
  }
  .grid {
    @media(max-width:767px) {
      width: 100%;
    }
  }
  .heading-card {
    h1 {
      font-family: "Soleil", sans-serif !important;
      font-size: 30px;
      font-weight: bold;
      color: $cpe-light-blue;
    }
    h3 {
      font-family: "Roboto", sans-serif !important;
      font-weight: 400;
      font-size: 24px;
      color: $cpe-dark-grey;
    }
  }
  .topic-categories {
    h4 {
      margin-top: 0;
      color: $cpe-darken-grey;
      display: flex;
      align-items: center;
      font-weight: 600;

      i {
        color: $cpe-light-blue;
        margin-left: 5px;
      }
    }

    a {
      font-size: 14px;
      color: $cpe-dark-grey;
    }
  }
}


.cpe-annual-cpe-passes {
  .view-all-passes-btn {
    font-family: Soleil,sans-serif!important;
    color: $cpe-dark-grey !important;
    text-transform: uppercase !important;
    border: 2px solid #fff !important;
    font-size: 15px;
    padding: 15px 30px;
    font-weight: 600;
  }
}

.cpe-by-the-numbers {
  h1 {
    font-family: "Soleil", sans-serif !important;
    color: $cpe-light-blue;
  }
  p {
    color: $cpe-dark-grey;
  }
  .about-us-btn {
    font-family: "Soleil", sans-serif !important;
    border: 1px solid $cpe-dark-grey;
    background-color: #fff;
    color: $cpe-dark-grey;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    padding: 1rem 5rem;
  }
}
.p-carousel-container .p-carousel-items-content {
  margin: 0px -7px;
}
.cpe-most-popular-courses .grid.container-full-width {
  width: 1090px;
}
#cpe-most-popular-courses .product-item {
  margin: 16px 5px;
}



.cpe-banner-number p.justify-content-center {
  text-align: center;
}

.cpe-banner-number .middle-content p {
  width: 85%;
}
.header-list-menu {
  height: auto !important;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 2px;
}
.cpe-wrapper .grid.flex.w-full.flex-wrap.align-items-center.justify-content-center {
  position: relative;
  top: 7px;
}


@media screen and (max-width:767px) {
  .annual-list {
    display: block !important;
  }

  .annual-list .justify-content-end {
    justify-content: start !important;
  }

  .cpe-banner-number .middle-content p {
    width: 100%;
  }
  span.p-input-icon-right.ng-star-inserted{    gap: 10px;}
  p.signmenu{
    font-size: 13px;
  }
  #mainMenu .p-menubar {
    padding: 10px 15px 0 15px;
}
}


@media screen and (max-width:568px) {
  .p-menubar .p-menubar-start {
    order: 2;
    width: 100%;
  }
  #mainMenu .p-menubar {
    padding: 10px 0;
    flex-wrap: nowrap;
  }
  span.p-input-icon-right.ng-star-inserted {
    gap: 15px;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
  }
  #mainMenu .p-menubar-end {
    width: 100%;
    padding:0 !important;
  }
  .p-menubar .p-menubar-button {
    order: 1;
  }
  input#search {
    position: static;
    min-width: 100%;
    box-shadow: none;
    border: 1px solid #f0f0f0;
    background-color: #f3f3f3;
}
#mainMenu .p-menubar-end i#search-input-icon {
  margin: 0;
}
  #mainMenu .p-menubar {
    padding: 10px 0 50px;
    flex-wrap: nowrap;
  }
  .input-bar-box {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;

    #search-input-icon {
      padding: 8px;
    }
  }
  .p-menubar-end .p-input-icon-right {
    position: static;
  }
  #mainMenu .p-menubar-end .pi-search {
    position: absolute;
    left: 0;
}
input#search {
  padding-left: 40px;
}
}


@media screen and (max-width:480px) {
  input#search {
    position: static;
    min-width: 95%;
    width: 100%;
  }
}

.find-your-ethics-course-btn {
  font-family: Soleil,sans-serif!important;
  border: 3px solid #fff !important;
  padding: 15px 30px !important;
  text-transform: uppercase;
  color: $cpe-dark-grey !important;
  font-size: 15px !important;
  font-weight: bold !important;
  background-color: rgb(246, 188, 65) !important;

  @media screen and (max-width:767px) {
    font-size: 12px;
    padding: 10px 20px !important;
    border: 2px solid #fff !important;
 }
}

.combo-pass-learn-more-btn {
  background-color: #e6e7e8 !important;
  color: $cpe-dark-blue !important;
  position: absolute;
}
.testimonials-slider {

  .p-carousel-item {
    .testimonial {
      background: rgba(255, 255, 255, 0.8);
      padding: 40px 20px;
      border-radius: 30px !important;
      box-sizing: border-box;
      width: 75%;
      margin: 20px auto;
      position: relative;

      :after {
        position: absolute;
        content: "";
        right: -41px;
        bottom: 20px;
        border-top: 20px solid transparent;
        border-left: 44px solid rgba(255, 255, 255, 0.8);
        transform: rotate(15deg);

        @media screen and (max-width:767px) {
          right: -33px;
          bottom: 20px;
          border-top: 20px solid transparent;
          border-left: 34px solid rgba(255, 255, 255, 0.8);
          transform: rotate(6deg);
        }

      }
    }
  }

  .testimonial-rating {
    .p-rating-icon-active {
      color: rgb(246, 188, 65) !important;
    }
  }

  .p-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.checkout-page-container {
  @media screen and (max-width:480px) {
    .cpe-wrapper {
        padding: 0 5px;
      }
  }

  @media screen and (max-width:767px) {
    /*.p-steps ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .p-steps .p-steps-item:before {
      content: " ";
      top: 100% !important;
      z-index: -1 !important;
    }

    .p-steps-item .p-menuitem-link {
      flex-direction: row !important;
      align-items: flex-start !important;
    }

    .p-steps .p-steps-item .p-menuitem-link {
      background: #fff !important
    }

    span.p-steps-number {
      margin-right: 10px !important;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
      margin-top: 4px !important;
    }*/

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
      font-size: 0;
    }

    .p-steps .step-1 .p-menuitem-link .p-steps-title::after {
      content: 'Order Summary';
      font-size: 1rem;
    }

    .p-steps .step-2 .p-menuitem-link .p-steps-title::after {
      content: 'Contact Info';
      font-size: 1rem;
    }

    .p-steps .step-3 .p-menuitem-link .p-steps-title::after {
      content: 'Billing Info';
      font-size: 1rem;
    }

    .p-steps .step-4 .p-menuitem-link .p-steps-title::after {
      content: 'Checkout';
      font-size: 1rem;
    }

    a.p-menuitem-link {
      max-width: 200px;
      width: 100%;
    }
  }

  @media screen and (max-width: 960px) {
    .p-datatable-thead > tr > th, #pr_id_2 .p-datatable-tfoot > tr > td {
      display: block !important;
    }
  }

  .workshop-add-to-cart-btn {
    min-height: 54px;
    border-radius: 20px;
    background-color: $cpe-dark-blue !important;
    border-color: $cpe-dark-blue !important;
    font-weight: 600;
  }

  .remove-icon {
    height: 2em !important;
    width: 2em !important;
  }

  .next-step-btn {
    background-color: #fff !important;
    color: $cpe-dark-blue !important;
    top: 30px;
  }
  .prev-step-btn {
    background-color: #fff !important;
    color: $cpe-dark-blue !important;
    top: 30px;
  }
  .input-mask-field {
    padding: 0 0.5em;
    display: block;
    .p-inputmask {
      border: none;
      padding: 0.5rem 0rem
    }
    .p-inputmask:focus {
      box-shadow: none !important;
    }
  }
}
.application-passes-header {
  font-size: 1.4em;
  color: rgb(51, 51, 51);
  font-weight: 700;
}
.pass-widget-label {
  color: rgb(51, 51, 51);
  font-size:12px;
  font-weight:900;
}
.course-feature-table {
  background-color: $cpe-light-grey;
  .title {
    font-weight: bold;
    color: $cpe-dark-grey;
    white-space: nowrap;
  }
  .desc {
    color: $cpe-dark-grey;
  }
}
#EthicsCourses {
  .ethics-courses-desc {
    color: $cpe-dark-grey;
  }
  .label {
    color: $cpe-dark-blue;
  }
  #ethics-courses-search-dropdown {
    .p-dropdown {
      border-color: $cpe-dark-grey;
    }
    .p-dropdown-label {
      font-weight: bold;
      color: $cpe-dark-grey;
    }
    .p-dropdown-trigger {
      color: $cpe-dark-grey;
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
      font-weight: bold;
      color: $cpe-dark-grey;
    }
    .p-dropdown .p-dropdown-clear-icon {
      color: $cpe-dark-grey;
    }
  }
  #search-ethic-courses-btn button {
    font-weight: 700 !important;
  }
}

.cpe-top-content {
  position: relative;
}

.cpe-top-content {

  .banner-img img {
    width: 390px;
    object-fit: cover;
    object-position: right;
    float: left;
  }

  @media screen and (max-width:767px) {
    .banner-img-col {
      display: none;
    }
  }
  @media screen and (max-width:1200px) {
    overflow: hidden;
  }
  h1 {
    font-family: "Soleil", sans-serif !important;
    font-size: 55px;
  }
  h3 {
    color: $cpe-dark-grey;
    font-size: 26px;
    font-weight: 400;
    font-family: "Roboto", sans-serif !important;
  }
  input {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;
    font-size: 22px;
    color: $cpe-darken-grey;
    border: 1px solid #f0f0f0;
    background-color: #f3f3f3;
    padding: 10px 15px;
  }
  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $cpe-darken-grey;
  }
  .p-inputtext:enabled:hover {
    border-color: rgb(176,176,176);
  }
  .p-input-icon-right {
    i {
      font-size: 22px;
      top: 38% !important;
      color: $cpe-light-blue !important;
    }
  }
  .p-input-icon-left {
    i {
      font-size: 22px;
      top: 38% !important;
      color: $cpe-darken-grey !important;
    }
    .p-inputtext {
      padding-left: 2.5rem;
    }
  }

  .search-people-action-buttons {
    .p-button {
      font-family: "Roboto", sans-serif !important;
      color: #fff;
      font-size: 17px;
      background-color: $cpe-dark-blue;
      border-color: $cpe-dark-blue;
      border-radius: 30px;
      padding: 10px 16px !important;
    }
  }
}

@media(max-width: 991px){
  .annual-list {
    h1 {
      font-size: 22px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
  .wrapper-full-width {
    width: 100% !important;
  }
}


@media screen and (min-width:1367px) {
  #cpe-upcoming-conferences .container-full-width {
    width: 100% !important;
  }
}

@media(max-width:1366px){
  .container-full-width {
    width: 100% !important;
  }
}

.cpe-take-a-seat {
  position: relative;
  overflow: hidden;
  .banner-img img {
    width: 475px;
    object-fit: cover;
    object-position: right;
    float: left;
  }

  @media screen and (max-width:767px) {
    .banner-img-col {
      display: none;
    }
  }
  h1 {
    font-family: "Soleil", sans-serif !important;
    font-size: 30px;
    font-weight: bold;
    color: $cpe-dark-grey;
    line-height: 1.5;
  }
  h3 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500;
    color: $cpe-dark-grey;
    font-size: 24px;
  }
  .take-a-seat-action-buttons {
    .virtual-conf-btn {
      text-transform: uppercase;
      color: #fff;
      background-color: $cpe-dark-blue;
      border-color: $cpe-dark-blue;
    }
    .in-person-conf-btn {
      text-transform: uppercase;
      color: $cpe-dark-blue;
      background-color: #fff;
      border-color: $cpe-dark-blue;
    }
  }
}

.slider-container {
  .p-carousel-container > button.p-carousel-next {
    right: -50px;
  }
  .p-carousel-container > button.p-carousel-prev {
    left: -60px;
  }
  .p-carousel-container > button {
    position: absolute;
    top: 25%;
  }
  .p-carousel-container {
    position: relative;
    overflow: visible;
  }
  .p-carousel-content{
    overflow:visible !important;
  }
}

#cpe-most-popular-courses .slider-container {
  .p-carousel-container > button.p-carousel-next {
    right: 0px;
  }
}

app-in-demand-courses-widget {
  .p-tabview .p-tabview-panels {
    padding: 0;
  }
}

@media screen and (max-width:767px) {
  .cpe-most-popular-courses .slider-container .p-carousel-container>button.p-carousel-next {
    right: 0;
  }

  .cpe-most-popular-courses .slider-container .p-carousel-container>button.p-carousel-prev {
    left: 0;
  }

  .cpe-most-popular-courses {
    text-align: center;
  }

  .cpe-most-popular-courses .product-item {
    margin: 0 auto !important;
    padding: 5px 0 0;
  }
 .in-demand-slider .p-carousel-container .p-carousel-items-content, .upcoming-conferences-slider .p-carousel-container .p-carousel-items-content {
    margin: 0;
    max-width: 90%;
    margin: 0 auto;
}
.in-demand-slider .p-carousel-container > button.p-carousel-next, .upcoming-conferences-slider .p-carousel-container > button.p-carousel-next {
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.in-demand-slider .p-carousel-container > button.p-carousel-prev, .upcoming-conferences-slider .p-carousel-container > button.p-carousel-prev {
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
}
#cpe-most-popular-courses .product-item {
   max-height: 100%;
}
.cpe-dark-blue-text-home {
  color: $cpe-dark-blue;
}

.cpe-testimonials {
  h1 {
    color: $cpe-dark-grey;
  }
}

// passes page

#CpePasses {

  .col-2.pass-box {
    border: 8px solid #fff;
    padding: 0;
  }

  .col-2.pass-box.acive-box {
    border: 8px solid $cpe-light-blue;
    padding: 0;
    position: relative;
    margin-bottom: 30px;
    border-bottom: none;

    svg {
      position: absolute;
      width: 110%;
      left: -8px;
      bottom: -30px;
      z-index: -1;
    }
  }

  // .info-box {
  //   background: #fff;
  // }
  .title-pass {
    background: $cpe-light-blue;
    color: #fff;
    font-size: .875rem;
    font-weight: normal;
    grid-area: featuredHeader;
    margin: 0;
    padding: 0.65rem 0;
    width: 100%;
  }

  .hiddu {
    visibility: hidden;
  }

  .pass-box span {
    align-items: start;
    font-family: "Euclid Circular A Bold", Helvetica, Arial, sans-serif;
    font-size: 14px !important;
    font-weight: bold;
    grid-area: productTitle;
    text-transform: uppercase;
    text-align: center;
    display: block;
  }

  p.ac-prize {
    color: $cpe-light-blue;
    font-size: 2rem;
    margin: 0;
    padding: 0 8px;
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  span.round {
    width: 10px;
    height: 10px;
    background: #0061b8;
    border-radius: 50%;
    text-align: center;
    display: block;
    display: flex !important;
    justify-content: center;
    margin: 0 auto;
  }

  p.old-prize {
    margin: 0;
    font-size: 20px;
    color: #4a4a4a;
    text-decoration: line-through;
  }

  .btn-btn-gp button.cart-pass {
    background-color: #fdc20d;
    border: 2px solid #fdc20d;
    color: #122048;
    font-family: "Euclid Circular A Bold", Helvetica, Arial, sans-serif !important;
    font-size: 15px;
    line-height: 1.5rem;
    padding: 0.375rem 0.75rem;
    text-decoration: none;
    transition: border 0.25s ease, background-color 0.25s ease;
    margin: 3px 0 0;
    border-radius: 4px;
  }

  button.cart-pass:hover {
    background-color: #122048;
    cursor: pointer;
    color: #fdc20d;
    border-color: #122048;
    -webkit-transition: border .25s ease, background-color .25s ease;
    transition: border .25s ease, background-color .25s ease;
  }

  .col-2,
  .col {
    padding: 5px;
    color: #4a4a4a;

  }

  .col {
    text-align: left;
    padding-left: 15px !important;
  }

  .col-12 .grid {
    border: 1px solid #000;
    padding: 0;
    align-items: center;
    border-bottom: 0;
  }

  .col-12 {
    padding-bottom: 0;

    &:last-child .grid {
      border-bottom: 1px solid #000;
    }

    &:first-child .grid {
      padding-top: 0 !important;
    }
  }

  .even-color {
    background: #f4f4f4;
  }

  .col-2.even-border {
    border: 8px solid #f4f4f4;
  }
}

.non-active {
  border: 0 !important;
}

.non-active svg,
.non-active .title-pass {
  visibility: hidden;
}

span.evening {
  background: #f4f4f4;
}

@media (max-width:1199px) and (min-width:993px) {
  #CpePasses .col-12 .grid .col-4 {
    width: 15%;
  }

  #CpePasses .col-2.pass-box {
    width: 25%;
  }

  #CpePasses .col-12 .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;

  }

  #CpePasses .col-12 .grid .col-2 {
    width: 25%;
  }
}

//
// This is the primary breakpoint when the screen goes from single column
// to full width layout
//
@media(max-width:992px) {

  .course-feature-table-box {
    display: flex !important;
    width: unset !important;
    flex: 0 1 auto !important;
  }

  #CpePasses .col-2.pass-box {
    width: 25%;
    border: 0 !important;
  }

  .head-top-menu {
    display: none;
  }

  #CpePasses .col-2.pass-box.acive-box svg {
    display: none;
  }

  #CpePasses .pass-box span.evening,
  .evening-combo {
    margin-top: 10px;
  }

  .non-active {
    margin-top: -30px;
  }

  .content-full .col-4 {
    width: 100%;
    text-align: left;
  }

  .content-full .col-2 {
    width: 25%;
  }

  .content-full .col-4 {
    width: 100%;
    text-align: left;
    background: #e1e1e1;
    color: #000;
  }

  #CpePasses .col-12.content-full .grid {
    border: 0;
  }

  /*28-06-2023*/
  #CpePasses .col-2.pass-box.acive-box {
    margin-top: 0px;
  }
}


@media screen and (max-width:767px) {
  // #CpePasses .col-2.pass-box {
  //   width: 50%;
  //   border: 0 !important;
  // }
  // .content-full .col-2 {
  //   width: 50%;
  // }
  // .cpe-passes .pl-6,.cpe-passes .pr-6{padding: 20px 0 0 !important;}

  .cpe-subscribe-inputs-container {
    margin-top: 0px;
    padding-top: 0px !important;
  }


  #CpePasses p.ac-prize {
    font-size: 20px;
  }

  #CpePasses .pass-box span {
    font-size: 11px !important;
  }

  #CpePasses .col-2,
  #CpePasses .col {
    font-size: 14px;
  }


}


//
// Shows when the display is at it's smallest layout in a single column
//
@media screen and (max-width:575px) {

  .contact-form-subheader {
    font-size: 1.08em;
  }

  #CpePasses .title-pass {
    font-size: 10px;
    padding: 10px 10px;
  }

  div.annual-passes-widget-container {
    width: 50% !important;
    margin: 0 auto !important;
    min-width: 300px;
  }

  #CpePasses .pass-box span {
    font-size: 11px !important;
    word-break: break-word;
    width: auto;
    text-wrap: balance;
  }

  #CpePasses .pass-box span.evening,
  span.evening-combo {
    margin-top: 10px;
    min-height: 42px;
    line-height: 1.5;
    vertical-align: middle;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  #CpePasses .col-2,
  #CpePasses .col {
    font-size: 14px;
    word-wrap: break-word;
  }
}

@media screen and (max-width:480px) {

  #CpePasses .pass-box span.evening,
  span.evening-combo {
    min-height: 92px;
    padding: 0 10px;
  }

  #CpePasses p.ac-prize {
    height: auto;
    font-size: 14px;
    padding: 15px 0;
  }

  #CpePasses .action-buttons {
    height: auto;
  }

  .non-active svg,
  .non-active .title-pass {
    display: none;
  }

  #CpePasses .col-2,
  #CpePasses .col {
    font-size: 11px;
    padding: 10px;
  }

  .cpe-passes .pl-6,
  .cpe-passes .pr-6 {
    padding: 20px 0px 0 !important;
  }

  #CpePasses .title-pass {
    font-size: 10px;
    padding: 10px 10px;
  }
}

.course-codes-datatable {
  .p-datatable-header {
    background-color: #fff !important;
    .p-input-icon-left > i:first-of-type {
      left: 1rem !important;
    }
  }
}

#courseStateList, #courseByState {
  p {
    color: $cpe-dark-grey;
  }
  h1 {
    font-size: 35px;
    color: $cpe-dark-grey;
  }
  .p-button {
    background-color: #fff;
    color: #004b73;

    .p-button-label {
      line-height: 2;
    }
  }
  .search-people-action-buttons {
    .p-button {
      color: #fff;
      background-color: $cpe-dark-blue;
      border-color: $cpe-dark-blue;
      border-radius: 30px;
      padding: 5px !important;
    }
  }
}
.p-menubar .p-submenu-list {
  z-index: 2 !important;
}
@media (max-width:1199px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link:first-child {
    font-size: 14px;
  }
  p.signmenu {
    width: 80px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  span.p-input-icon-right.ng-star-inserted {
    gap: 20px;
  }
}

@media (min-width: 1200px) {
  #cpe-explore-in-demand {
    .view-all-courses-btn {
      font-size: 18px;
    }
  }
  #cpe-most-popular-courses {
    .course-offering-card, .course-guarantee-card {
      h1 {
        font-size: 26px;
      }
      .p-button {
        font-size: 18px;
        .pi {
          font-size: 18px;
        }
      }
    }
    .popular-card {
      h1 {
        font-size: 55px;
      }
      h3 {
        font-size: 30px;
      }
    }
    .view-all-courses-btn {
      font-size: 18px;
    }
  }
  .find-your-ethics-course-btn {
    font-size: 18px !important;
  }
  .cpe-take-a-seat, #cpe-upcoming-conferences, #cpe-explore-all-topics, .cpe-by-the-numbers {
    h1 {
      font-size: 45px !important;
      .small-text {
        font-size: 37px;
      }
    }
    h3 {
      font-size: 30px !important;
    }
  }

  #contactForm {
    h1 {
      font-size: 38px !important;
      .small-text {
        font-size: 29px;
      }
    }
    h3 {
      font-size: 28px !important;
    }
  }
  .cpe-banner-number {
    .middle-content p {
      font-size: 24px;
    }
    .number-info p {
      font-size: 18px;
    }
  }

}

@media screen and (max-width:767px) {
  .cpe-take-a-seat, #cpe-upcoming-conferences, #cpe-explore-all-topics, .cpe-by-the-numbers {
    h1 {
      font-size: 32px !important;
    }
  }

  .cpe-by-the-numbers {
    .divider {
      width: 20% !important;
    }
  }
}

@media screen and (max-width:575px) {
  .cpe-take-a-seat, #cpe-upcoming-conferences, #cpe-explore-all-topics, .cpe-by-the-numbers, #contactForm {
    h1 {
      font-size: 25px !important;
    }
  }
}

.p-button.filter-courses {
  font-family: "Soleil", sans-serif !important;
  color: #fff;
  font-size: 17px;
  background-color:$cpe-light-blue;
  border-color: $cpe-light-blue;
  padding: 10px 16px !important;
  font-weight: 600;
}
.responsive-search-result-table {
  td {
    padding: 0.5rem 0.5rem 0.2rem 0.5rem !important;
    display: flex;
    width: 100% !important;
    align-items: flex-end !important;
    justify-content: space-between;

    .p-column-title {
      font-family: "Roboto", sans-serif !important;
      font-weight: 600;
      color: $cpe-dark-grey;
      p {
        font-weight: normal;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .view-pass-details-btn {
        font-family: Soleil,sans-serif!important;
        border: none;
        background-color: #fff;
        color: $cpe-dark-blue;
        text-transform: uppercase;
        padding: 0 !important;
        .p-button-icon-right {
          margin-top: -3px;
          margin-left: 0;
        }
      }
    }
  }
  tr {
    border-bottom: 1px solid #000;
  }
}
.left .p-toolbar .p-button {
  padding: 10px;
}

.sidebar {

  /* FLOATING SIDEBAR */
  ul {
    clear: both;
    position: fixed;
    display: block;
    list-style-type: none;
    left: 0;
    top: 250px;
    -webkit-animation: slideright 1s forwards;
    -webkit-animation-delay: 1.5s;
    animation: slideright 1s forwards;
    animation-delay: 1.5s;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    z-index: 11;
  }

  @keyframes slideright {
    100% {
      left: 0;
    }
  }

  li {
    margin-top: 0;
    margin-right: 0;
    padding: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    background-color: rgba(30, 45, 127, 0.75);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: width 0.5s, background-color 0.1s;
    writing-mode: vertical-rl;
    -webkit-transition: width .5s, background-color .1s;
    transition: width .5s, background-color .1s;

    :hover {
      padding: 18px;
      transition: 0.15s;
    }
  }

  li span,
  li a {
    font-size: 19px;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: #ebebeb;
  }

  li:hover {
    background-color: rgba(30, 45, 127, 1);
    color: #fff;
  }
}
.topic-search-container {
  .p-scrollpanel-bar-x {
    visibility: hidden;
  }
}

.search-results p-table:not(.h-full-loading) .p-datatable .p-datatable-thead > tr > th:nth-child(2) {
  white-space: nowrap;
  max-width: 150px;
  position: absolute;
  margin-left: -50px;
}
.related-courses p-table:not(.h-full-loading) .p-datatable .p-datatable-thead > tr > th:nth-child(2){
  white-space: nowrap;
  max-width: 150px;
  //position: absolute;
  margin-left: -50px;
}

.search-results .responsive-search-result-table .p-datatable-loading-overlay {
  margin: auto;
  margin-top: 100%;
}
.search-results .p-datatable .p-datatable-thead > tr > th {
  white-space: nowrap;
  padding: 1rem 0.5rem;
}
.search-results .p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem 0.5rem;
}
.search-results .h-full-loading .p-datatable {
  height: 100%;
}

.p-datatable .p-datatable-tbody > tr > td.search-col-price, .p-datatable .p-datatable-tbody > tr > td.search-col-hours {
  white-space: nowrap; text-align: right;
}

.cpe-pass-modal.p-dialog {
  box-shadow: none;

  .heading-text {
    color: #fff !important;
    text-transform: none !important;
    font-size: 28px !important;
    font-family: "Soleil", sans-serif !important;
    font-weight: 600;
    padding: 5px 0;
  }

  .p-dialog-header {
    background: transparent;
    padding: 0 !important;
      @media (max-width: 992px) {
        padding-right: 10px !important;
      }
    .p-dialog-title {
      color: #fff !important;
      text-transform: none !important;
      font-size: 28px !important;
      font-family: "Soleil", sans-serif !important;
      width: 100%;
      text-align: center;
    }

    .p-dialog-header-icons {
      background-color: #fff;
      border-radius: 50%;
    }

  }

  .p-dialog-content {
    background: transparent;
  }

  .footer-text {
    color: #fff;
  }
  .p-card-title{
    font-size: 2rem !important;
    @media (max-width: 1200px) {
      font-size: 1.5rem !important;
    }
  }
  .p-card-content {
    padding: 1rem 0 0;

    .hours-selection-label {
      font-size: 22px;
    }

    .price-tag {
      height: auto !important;
      font-size: 28px !important;
      margin-bottom: 0.5rem !important;
      @media (min-width: 1200px) {
        margin-bottom: 20px !important;
      }
    }
    .pass-spec p{
      height: auto !important;
      margin-bottom: 0.5rem !important;
      line-height: normal !important;
    }
  }

  .p-card {
    position: relative;

    .p-card-header {
      .header-tag {
        padding: 5px 10px;
        font-size: 16px;
        background-color: rgb(246, 188, 65);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        font-weight: 700;
      }
    }
    .p-card-body {
      padding: 0.5rem 0 !important;
      .p-card-title {
        height: auto !important;
      }
      .p-card-content {
        padding-top: 0.5rem !important;
      }
    }
  }
}
#cpe-pass-dialog .p-component-overlay {
  backdrop-filter: blur(10px);
}

.applicable-passes-partial > div:first-child {
  padding-left: 16px !important;
}

.applicable-passes-partial > div {
  margin-right: 15px;
}

.applicable-passes-all {
  margin-left: 16px;
}

i.cpe-credit-price-separator {
  font-size: .3rem;
  vertical-align: middle;

}

/* Take up the space on the first content p */
div.cpe-content-section > div.field-items > div.field-item > p {
  padding-top: 0px;
  margin-top: 0px;
}


.seminar-venu-label {
  font-weight: bold;
  color: rgb(51, 51, 51);
  white-space: nowrap;
  font-size: 16px;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 0.5rem !important;
}

.course-id-display {
  margin-top: -10px; margin-bottom: 10px; color: rgb(51, 51, 51);
}

.search-result-course-title {
  font-size: 17px;
}

.prev-step-btn-top {
  background-color: #fff !important;
  color: $cpe-dark-blue !important;
  top: 15px;
}

.terms-template > h3 {
  margin-block-end: 0px;
}

.terms-template > p {
  margin-block-start: 0px;
}
.opt-in-sms > label {
  font-size: 15px;

  @media (max-width: 520px) {
    font-size: 14px !important;
  }

  @media (max-width: 480px) {
    font-size: 13px !important;
  }

  @media (max-width: 420px) {
    font-size: 12px !important;
    margin-left: 0.1rem;
  }

}

.no-top-margin {
  margin-top: 0px;
}

.theme-top-margin-offset {
  margin-top: -10px;
}

.change-attendeees-modal {
  width: 50vw;
  @media (max-width: 767px) {
    width: 90%;
  }
}
